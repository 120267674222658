import styled, { css } from 'styled-components'

export const ClientsHomeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f4f4f4;
  justify-content: space-between;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;

  > * + * {
    margin-top: 2rem !important;
  }

  @media (min-width: 768px) {
    padding-top: 4.5rem;
    justify-content: center;

    > * + * {
      margin-top: 3rem !important;
    }
  }
`
export const NegativeMarginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;

  > * + * {
    margin-top: 2rem !important;
  }

  @media (min-width: 768px) {
    padding-top: 4.5rem;
    justify-content: center;
    margin-bottom: -100px;

    > * + * {
      margin-top: 3rem !important;
    }
  }
`

export const DealersContainer = styled(Container)`
  height: calc(100vh - 4rem);
  max-height: 100vh;
`

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 1rem !important;
  }
`

export const DealersForm = styled.form`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 1.5rem !important;
  }
`

export const Title = styled.h1`
  font-size: 2rem !important;
  line-height: 1.5 !important;
  text-align: center;
  font-weight: 500 !important;

  @media (min-width: 768px) {
    font-size: 3rem !important;
  }
  @media (min-width: 1440px) {
    font-size: 3.75rem !important;
  }
`

export const ClientName = styled.h1`
  font-size: 3rem !important;
  text-align: center;
  font-weight: 500 !important;

  @media (min-width: 768px) {
    font-size: 4rem !important;
  }
  @media (min-width: 1440px) {
    font-size: 7.5rem !important;
  }
`

export const FormSection = styled.section`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;

  #select-container {
    min-height: 48.4px !important;

    span:first-child {
      padding-right: calc(16px + 0.4rem + 1.5rem);
    }

    > div > div > div {
      line-height: 2rem;
    }
  }

  > div {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  label {
    height: 0;
    display: none;
  }

  input {
    flex: 1;
    min-height: 46.4px;
    max-height: 46.4px;
    font-size: 0.9rem;
    line-height: 1.6rem;
    margin: 0 !important;
    padding: 0 14px;
    border-radius: 0.2rem;
    border: 1px solid #6e6e6e !important;
    outline: none;
    transition: box-shadow 0.3s ease-in-out;
    letter-spacing: 1px;
    color: #00095b;

    &:focus,
    &:active {
      box-shadow: 0 1rem 1rem 0 rgb(0 0 0 / 10%), 0 2rem 2rem 0 rgb(0 0 0 / 10%), 0 3rem 3rem 0 rgb(0 0 0 / 15%);
    }
  }

  @media (min-width: 768px) {
    p {
      font-size: 1.6rem;
      white-space: nowrap;
      word-break: keep-all;
    }
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

export const CustomButton = styled.button`
  max-width: 100% !important;
  border-radius: 9999px !important;
  padding: 0.5rem 1rem !important;
  background: #00095b !important;
  color: #fff !important;
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02px !important;
  font-weight: 700 !important;
  box-shadow: 0 10px 10px #00000029 !important;

  @media (min-width: 768px) {
    font-size: 1.3rem !important;
  }
  @media (min-width: 1024px) {
    padding: 0.5rem 2rem !important;
  }
  @media (min-width: 1440px) {
    font-size: 1.6rem !important;
  }
`

export const ErrorMessage = styled.span`
  color: #d62d14;
  font-size: 0.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  display: inline;
`

export const LogoImg = styled.img`
  height: 4rem;

  @media (min-width: 768px) {
    height: 6rem;
  }
  @media (min-width: 1440px) {
    height: 8rem;
  }
`

export const BottomLogoImg = styled.img`
  height: 2rem;

  @media (min-width: 768px) {
    height: 3rem;
  }
`
export const WhatsappImg = styled.img`
  max-width: 3rem;
  aspect-ratio: 1;

  @media (min-width: 768px) {
    max-width: 5.6875rem;
  }
`

export const ClientsButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: 1.5rem !important;
  }

  > div {
    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: 1rem !important;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    > * + * {
      margin-left: 1rem !important;
      margin-top: 0 !important;
    }
  }

  @media (min-width: 1024px) {
    > * + * {
      margin-left: 2rem !important;
    }
  }
`

export const QrCodeContainer = styled.div`
  padding: 1rem;
  background: #fff;
`

export const BottomImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  padding-bottom: 2rem;
  > * + * {
    margin-top: 1rem !important;
  }

  p {
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.8;
    max-width: 128px;
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0;
  }
  @media (min-width: 1024px) {
    right: 2rem;
  }
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  > * + * {
    margin-top: 1rem !important;
  }

  @media (min-width: 1024px) {
    margin-left: -4rem;
  }
`

export const CustomIframe = styled.iframe`
  aspect-ratio: 16 / 9;
  height: 75vh;
  width: 100%;
`

export const ModalCloseButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BottomIllustrationContainer = styled.div`
  background: #f4f4f4;

  svg {
    margin-bottom: -4px;
  }
`
